export default class Store {
    constructor(data = {}) {
        this.id = data.id || ''
        this.pk = data.pk || ''
        this.name = data.name || ''
        this.code = data.code || ''
        this.registers = data.registers || []
        this.oldRegisters = data.oldRegisters || []
        this.nature = data.nature || 'retail'
        this.segmentId = data.segmentId || ''
        this.warehouseId = data.warehouseId || ''
        this.subWarehouse = data.subWarehouse || ''
        this.saleChannelId = data.saleChannelId || ''
        this.address = data.address || ''
        this.user = data.user || {}
        this.lockDelete = data.lockDelete || 'no'
        this.phone = data.phone || ''
        this.status = data.status || 'active'
        this.allowStock = data.allowStock || false
        this.allowNegativeStock = data.allowNegativeStock || false
        this.username = data.username || ''
        this.secretCode = data.secretCode || ''
        this.product = data.product || 1
        this.priceLevel = data.priceLevel || 1
        this.campaign = data.campaign || 1
        this.channel = data.channel || 1
        // this.modifier = data.modifier || 1
        this.exRate = data.exRate || 1
        this.regiConfig = data.regiConfig || 1
    }

    constuct(data) {
        this.constructor(data);
    }
}